import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";

const Product = () =>{
    const { productSlug } = useParams();
    const [product, setProduct] = useState([]);

    useEffect(() => {
        fetch(`https://dummyjson.com/products/1`)
          .then((response) => response.json())
          .then((users) => setProduct(users));
      }, []);
    console.log(productSlug)
    return (
        <>
        <div><a href="https://amzn.to/4byoLv8" target="_blank">Aspirador de pó sem saco Electrolux filtro HEPA 1300W bocal estofados cabo retratil controle velocidade ABS03 220</a></div>
        <div><p>ração origens <a href="https://www.amazon.com.br/Ra%C3%A7%C3%A3o-Origens-Class-Grande-Frango/dp/B095QNFYRQ?crid=3L692PE7W0395&dib=eyJ2IjoiMSJ9.A1aqgZspPGbFbcmstTTx-p8ht6FS7azp8VoASck5p22sxNfw1FTIULSBbA-x9Vl2VTOPw83ZpSAuZ7HDNI7EAUjaXEwDPPxXQ2Ph7DR67aZfxC6s1tGf8aWpdZnlBcyaJmBYM_lydx1sBHNaBn0eKIKUruaA8gW1kzZFS4fkC0AMW_VGp6_dy_ePlU9UfmhcYBcgisR1nBg5BY4AvkUvfQsU2ARwfWJsP5fqUP6a_LbAensE9DViM-k9kKV63G14AFF4prydwq5eel0AZlaiQnx1DpEHHginp4GMGtvu-n0.8Rco5F59PQSfxlMOxxmD2_226pLKp40I4ZQ0B_v2pJA&dib_tag=se&keywords=ra%C3%A7%C3%A3o+origens+15kg&qid=1720133737&sprefix=ra%C3%A7%C3%A3o+origen%2Caps%2C216&sr=8-2&ufe=app_do%3Aamzn1.fos.6d798eae-cadf-45de-946a-f477d47705b9&linkCode=ll1&tag=shopzaz-20&linkId=fe6d554116012803bcd499876cbfc11b&language=pt_BR&ref_=as_li_ss_tl" target="_blank">Ração origens</a>.</p></div>
        <div>react-helmet (meta data)</div>
        <div>imagens</div>
        <div>nome</div>
        <div>preço comprar na amazon</div>
        <div>estrelas de avaliações</div>
        <div>descrição</div>
        <div>Q&A</div>
        <div>avaliações</div>
        <div>relacionados</div>
        <div><p>Check out <a href="https://amzn.to/4cqRbYY" target="_blank">under armour</a>.</p></div>
        <div><p>Check out also <a href="https://www.amazon.com.br/T%C3%AAnis-Under-Armour-Tribase-Branco/dp/B0CWPS5GTH?pd_rd_w=F4OYn&content-id=amzn1.sym.9a9cc541-9cd4-4650-9da4-9dc221f764e1%3Aamzn1.symc.40e6a10e-cbc4-4fa5-81e3-4435ff64d03b&pf_rd_p=9a9cc541-9cd4-4650-9da4-9dc221f764e1&pf_rd_r=4SJ4FRV00738QP36QYA9&pd_rd_wg=GuBsR&pd_rd_r=820cf00f-d826-435c-9855-f0f79ffea263&pd_rd_i=B0CWPS5GTH&linkCode=ll1&tag=shopzaz-20&linkId=6c359294e6505f37783f0018b5e32358&language=pt_BR&ref_=as_li_ss_tl" target="_blank">under armour</a>.</p></div>
        
        </>
    )
}
export default Product;