import { Route, Routes } from "react-router-dom";
import Header from "./routes/header/header.component";
import Product from "./routes/product/product.component";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Header />}>
        <Route path="p/:productSlug" element={<Product />}></Route>
      </Route>
    </Routes>
  );
};

export default App;
