import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as CrownLogo } from "../../assets/crown.svg";
import Footer from "../footer/footer.component";

const Header = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="columns-2">
          <div>
            <Link className="logo-container" to="/">
              <CrownLogo className="logo" />
            </Link>
          </div>
          <div>Shopzaz</div>
        </div>
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default Header;
